import React, { ReactElement } from "react"
import { PageProps } from "gatsby"
import { HomeCanvas } from "../components"
import "@fontsource/josefin-slab"

function Index(_props: PageProps): ReactElement {
  return (
    <>
      <h1 className="text-7xl text-center text-cyan mt-4">Charles Desbiens</h1>
      <HomeCanvas />
    </>
  )
}

export default Index
